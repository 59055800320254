import * as React from "react";
import { UserContext } from "../context/user-context";

function SvgComponent(props) {
  const { mobileTheme, isMobile } = React.useContext(UserContext);
  return (
    <svg
      id="Final_Exports"
      data-name="Final Exports"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 450 1080 150"
      {...props}
    >
      <defs>
        <style>
          {!isMobile
            ? ".cls-2,.cls-4{fill:#292727;stroke:#292727;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.cls-4{fill:#43d662;stroke:#43d662}"
            : `.cls-2,.cls-4{fill:${mobileTheme.FONT2};stroke:${mobileTheme.FONT2};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.cls-4{fill:#43d662;stroke:#43d662}`}
        </style>
      </defs>
      <path
        d="M177.87 539.92a52.62 52.62 0 01102.22-17.52H265.8a39.46 39.46 0 100 35h14.29a52.62 52.62 0 01-102.22-17.51z"
        strokeWidth="3px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={isMobile ? mobileTheme.FONT2 : "#292727"}
        stroke={isMobile ? mobileTheme.FONT2 : "#292727"}
      />
      <path
        className="cls-2"
        d="M360.37 557.6v35h-13.1V585a35.36 35.36 0 01-21.94 7.66 35 35 0 1135-35zm-13.1 0a21.94 21.94 0 10-21.94 21.94 22 22 0 0021.94-21.94zM407.88 522.57v13.09a21.85 21.85 0 00-21.77 21.94v35h-13.27v-35a35.09 35.09 0 0135.04-35.03z"
      />
      <path
        d="M411.28 488.05h105.27v13.26h-46.09v91.33h-13.09v-91.33h-46.09z"
        fill="#43d662"
        stroke="#43d662"
        strokeWidth="3px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cls-4"
        d="M528.12 522.57v13.09a21.86 21.86 0 00-21.77 21.94v35h-13.26v-35a35.08 35.08 0 0135.03-35.03zM604.77 557.6v35h-13.1V585a35.35 35.35 0 01-21.93 7.66 35 35 0 1135-35zm-13.1 0a21.94 21.94 0 10-21.93 21.94 22 22 0 0021.93-21.94zM615 557.6a35 35 0 0167.51-13.09h-15a21.85 21.85 0 100 26.36h15A35.06 35.06 0 01615 557.6zM708.29 592.64H695.2V487.53h13.09v105.11zm26.7-.17l-26.7-35 26.7-35h16.67l-26.87 35 26.87 35zM751.78 557.6a35 35 0 0170.07 0 40 40 0 01-.68 6.64H765.9a21.84 21.84 0 0020.91 15.3 22.55 22.55 0 0015.65-6.46h15.82a35.06 35.06 0 01-66.5-15.48zm14.12-6.46h41.83a22.17 22.17 0 00-20.92-15.31 21.85 21.85 0 00-20.91 15.31zM832.06 557.6a35.1 35.1 0 0157-27.38v-42.69h13.1v70.07a35 35 0 11-70.07 0zm13.09 0a21.94 21.94 0 1021.94-21.94 22 22 0 00-21.94 21.94z"
      />
    </svg>
  );
}

export default SvgComponent;
