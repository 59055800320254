import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/user-context";
import LogoText from "@/app/_components/logos/LogoTest";
import { SignOutButton } from "@clerk/nextjs";
import { DealershipContext } from "../context/dealership-context";
import { IconChevronDown } from "@tabler/icons-react";
import { Dealership } from "../context/dealership-context-wrapper";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import NavMenuMobile from "../nav-menu-mobile";
import Link from "next/link";

export default function MobileHeader() {
  const { mobileTheme } = useContext(UserContext);
  const [showMenu, setShowMenu] = useState(false);
  const { currentDealership, dealerships, setCurrentDealership } =
    useContext(DealershipContext);

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <div
        style={{ backgroundColor: mobileTheme.BACKGROUND }}
        className={`flex relative shadow-md justify-between items-center w-full h-[8vh] bg-background-200 z-[200]`}
      >
        <Link href={"/v2/dashboard"}>
          <LogoText width="100px" />
        </Link>
        <div>
          <Menu open={menuOpen} handler={setMenuOpen}>
            <MenuHandler>
              <Button
                variant="text"
                className={`flex items-center text-${mobileTheme.FONT3} text-[0.5rem] w-44 px-2`}
              >
                {currentDealership
                  ? currentDealership.name
                  : "Select Dealership"}{" "}
                <IconChevronDown
                  className={menuOpen ? "rotate-180" : ""}
                  width={"20px"}
                />
              </Button>
            </MenuHandler>
            <MenuList>
              {dealerships.map((dealership: Dealership) => (
                <MenuItem
                  key={dealership.id}
                  onClick={() => {
                    setCurrentDealership(dealership);
                    window.location.reload();
                  }}
                >
                  {dealership.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>
        <button
          onClick={() => {
            setShowMenu((prev) => !prev);
          }}
          className="mx-6"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="27"
            viewBox="0 0 26 27"
            fill="none"
          >
            <path
              d="M23 0H3C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6H23C24.6569 6 26 4.65685 26 3C26 1.34315 24.6569 0 23 0Z"
              fill="#8BE69E"
            />
            <path
              d="M23 10.4362H3C1.34315 10.4362 0 11.7794 0 13.4362C0 15.0931 1.34315 16.4362 3 16.4362H23C24.6569 16.4362 26 15.0931 26 13.4362C26 11.7794 24.6569 10.4362 23 10.4362Z"
              fill="#8BE69E"
            />
            <path
              d="M23 20.8724H3C1.34315 20.8724 0 22.2156 0 23.8724C0 25.5293 1.34315 26.8724 3 26.8724H23C24.6569 26.8724 26 25.5293 26 23.8724C26 22.2156 24.6569 20.8724 23 20.8724Z"
              fill="#8BE69E"
            />
          </svg>
        </button>
      </div>
      {showMenu && <NavMenuMobile setMenuOpen={setShowMenu} />}
    </div>
  );
}
